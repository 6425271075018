import React from "react";
import styled from "styled-components";
import {
	FacebookShareButton,
	FacebookIcon,
	LinkedinShareButton,
	LinkedinIcon,
	TwitterShareButton,
	TwitterIcon,
	WhatsappShareButton,
	WhatsappIcon,
	PinterestShareButton,
	PinterestIcon,
	EmailShareButton,
	EmailIcon,
} from "react-share";
import useSiteMetadata from "../SiteMetadata";
import { getImageUrl, trackEvent } from "../utils";
import { border, color } from "../../theme";

const shareButtons = [
	{
		title: "eMail",
		Component: EmailShareButton,
		Icon: EmailIcon,
		getProps: (props) => ({
			subject: `recommended read: ${props.title}`,
			body: props.descriptionRaw,
			separator: "\n...\n\n",
		}),
	},
	{
		title: "LinkedIn",
		Component: LinkedinShareButton,
		Icon: LinkedinIcon,
	},
	{
		title: "Pinterest",
		Component: PinterestShareButton,
		Icon: PinterestIcon,
		getProps: (props, social, siteUrl) => ({
			description: props.descriptionRaw,
			media: getImageUrl(props.image, null, siteUrl),
		}),
	},
	{
		title: "Twitter",
		Component: TwitterShareButton,
		Icon: TwitterIcon,
		getProps: (props, social) => ({
			title: props.title, hashtags: props.tags,
			via: social.twitter.split("/").pop(),
		}),
	},
	{
		title: "Whatsapp",
		Component: WhatsappShareButton,
		Icon: WhatsappIcon,
		getProps: (props) => ({ title: props.title, separator: "\n\n" }),
	},
	{
		title: "Facebook",
		Component: FacebookShareButton,
		Icon: FacebookIcon,
		getProps: (props) => ({
			quote: props.descriptionRaw,
			hashtag: props.tags && props.tags.map((t) => `#${t}`).join(" ")
		}),
	},
];

const Container = styled.div`
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	${({ noMargin }) => !noMargin && "margin: 0 0 20px;"}
    padding-bottom: 4px;

	${border({ style: "dotted" })`bottom`}

	box-shadow: 0px -5px 6px -4px ${color("brand-dark", false)};

	.SocialMediaShareButton {
		cursor: pointer;
		&:focus {
			outline: none;
		}
	}
`;

const onShareButtonClick = (type, sharedUrl) => {
	trackEvent("SocialMediaShare", "social media share button click", `social-share-${type}`, sharedUrl);
};

const renderButtons = (props, social, siteUrl) =>
	shareButtons.map((btn) => {
		const btnProps = btn.getProps ? btn.getProps(props, social, siteUrl) : {};

		return <btn.Component
			{...btnProps}
			key={btn.title}
			additionalProps={{ title: `שתף ל-${btn.title}` }}
			beforeOnClick={() => onShareButtonClick(btn.title, props.url)}
			url={`${siteUrl}${props.url}`}>
			<btn.Icon size={42} />
		</btn.Component>;
	});

const SocialShare = (props) => {
	const { social, siteUrl } = useSiteMetadata();

	return <Container noMargin={props.noMargin}>
		{renderButtons(props, social, siteUrl)}
	</Container>;
};

export default SocialShare;

